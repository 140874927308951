import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import useFetch from '../ApiClient/GetApi';
import { IMAGE_URL } from '../Utils/urls'; // Assuming IMAGE_URL is a base URL for images
import Footer from './Footer';

export default function NewsDetails() {
    const location = useLocation();
    const newsId = location?.state?.id;

    // Fetch news details using the news ID
    const { apiData } = useFetch(`newsDetails/${newsId}`);
    const newsData = apiData?.data?.newsData || {}; // Destructure the data safely

    console.log(JSON.stringify(newsData)); // Debugging the API response

    return (
        <div>
            <Header />
            {/* Banner Section */}
            <div className="sab_banner overlay" style={{paddingTop:"50px",paddingBottom:"50px"}}>
                <div className="container">
                    <div className="sab_banner_text">
                        <h2>News Details</h2>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Details</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* News Details Section */}
            <div className="container my-5" >
                <div className="row">
                    <div className="col-md-12" style={{marginTop:"100px",marginBottom:"100px",textAlign:"justify"}}>
                        {/* Render News Title */}
                        <h1 className="news-title">{newsData.title}</h1>

                        {/* Render News Image */}
                        {newsData.image && (
                            <div className="news-image text-center my-4" style={{margin:"20px 0px",textAlign:"justify"}}>
                                <img 
                                    src={`${IMAGE_URL}${newsData.image}`} 
                                    alt={newsData.title} 
                                    style={{ maxWidth: '80%', height: '50%',objectFit:"cover"}} 
                                />
                            </div>
                        )}

                        {/* Render News Description */}
                        {newsData.description && (
                            <div 
                                className="news-description" 
                                dangerouslySetInnerHTML={{ __html: newsData.description }} 
                                style={{ lineHeight: '1.8', fontSize: '16px',textAlign:"justify" }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
