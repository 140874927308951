import React from 'react';
import Header from './Header';
import Footer from './Footer';
import DOMPurify from 'dompurify';
import useFetch from '../ApiClient/GetApi';
import { useNavigate } from 'react-router-dom';

export default function Ballot() {
    const { apiData } = useFetch("getAllBallots");
    const navigate = useNavigate();

    const BallotDetails = (id) => {
        navigate("/ballot-details", { state: { id } });
    };

    return (
        <>
            <Header />
            <div>
                <div className="sab_banner overlay" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                    <div className="container">
                        <div className="sab_banner_text">
                            <h2>Ballots</h2>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Ballots</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="city_services2_wrap">
                    <div className="container">
                        <div className="row">
                            {apiData?.data?.ballots?.length > 0 ? (
                                apiData.data.ballots.map((newsItem) => (
                                    <div className="col-md-4 col-sm-6" key={newsItem.id}>
                                        <div className="city_service2_fig">
                                            <figure className="overlay">
                                                <img src="extra-images/service-fig.jpg" alt="" />
                                                <div className="city_service2_list">
                                                    <div className="city_service2_caption">
                                                        <h4>{newsItem.ballotName}</h4>
                                                    </div>
                                                </div>
                                            </figure>
                                            <div className="city_service2_text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            newsItem.description.length > 120
                                                                ? `${DOMPurify.sanitize(newsItem.description).slice(0, 120)}...`
                                                                : DOMPurify.sanitize(newsItem.description)
                                                        ),
                                                    }}
                                                ></p>
                                                <a style={{cursor:"pointer"}} onClick={() => BallotDetails(newsItem.id)} className="see_more_btn">
                                                    See More <i className="fa icon-next-1"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12">
                                    <p className="text-center">There are No Ballots at this time.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
