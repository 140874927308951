import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function
	() {
	return (
		<div>
			<Header />

			<div class="sab_banner overlay " style={{paddingTop:"50px",paddingBottom:"50px"}}>
				<div class="container">
					<div class="sab_banner_text">
						<h2>About Us</h2>
						<ul class="breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item active">About Us</li>
						</ul>
					</div>
				</div>
			</div>
			<div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>Who We Are</h2>
								<p>Results in Africa is a leading data-driven platform dedicated to providing timely and reliable insights into key sectors of African economies. Our mission is to empower individuals, businesses, governments, and organizations with accurate, transparent data that drives informed decisions across the continent.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="extra-images/health-fig.jpg" alt="" />
							</div>
						</div>

					</div>

				</div>
			</div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div style={{textAlign:"justify"}}>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://th.bing.com/th/id/R.2af6011bb62de8c54979499cfc7561aa?rik=Q06rlCctkmcutw&pid=ImgRaw&r=0" alt="" />
							</div>
						</div>
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>What We Do</h2>
								<p>Our platform covers a wide range of data including election results, economic performance indicators, trade statistics, employment figures, and survey results. By providing this data in an accessible format, we bridge the gap between raw data and actionable insights.</p>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>Our Mission</h2>
								<p>Our mission is to foster transparency, accountability, and growth in Africa by offering reliable and easy-to-understand data across all sectors of the economy. We believe that data is a powerful tool for decision making, shaping policies, driving business strategies, and fostering civic engagement.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://www.saffronpharma.com/wp-content/uploads/2021/10/Image-for-Mission-tab.jpg" alt="" />
							</div>
						</div>

					</div>

				</div>
			</div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://th.bing.com/th/id/R.735f738ebcbc06ce6c0b5734efb017ce?rik=cch%2fuBBMvNJ6cQ&pid=ImgRaw&r=0" alt="" />
							</div>
						</div>
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>Why Results Matter</h2>
								<p>Whether you're a policy-maker analyzing economic trends, a business leader tracking market performance, or a citizen staying informed about elections, our data provides the insights needed to make informed decisions. We are committed to ensuring that every African has access to trustworthy data that reflects the true state of their nation’s progress.</p>
							</div>
						</div>


					</div>

				</div>
			</div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>Our Values</h2>
								<div style={{ textAlign: "start" }}>
									<p><strong>Accuracy</strong>: Providing reliable, up-to-date data from verified sources.
										<p><strong>Transparency</strong>: Ensuring openness in how we gather, present, and interpret data.</p>
										<p><strong>Inclusivity</strong>: Covering all regions of Africa and making data available to all.</p>
										<p><strong>Empowerment</strong>: Enabling users to make decisions based on credible data.</p>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://th.bing.com/th/id/R.2d268a4cac9d141f6955b9785823f6a4?rik=GydgKhUe02CwGg&pid=ImgRaw&r=0&sres=1&sresct=1" alt="" />
							</div>
						</div>

					</div>

				</div>
			</div>
			<div class="city_health_wrap" style={{ padding: "40px 0px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://th.bing.com/th/id/R.a1b8c91ef880fd830cd3ce6e97939a69?rik=onomteohXnSQ6w&pid=ImgRaw&r=0" alt="" />
							</div>
						</div>
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>How We Source Data</h2>
								<p>Our data is sourced from trusted national and international agencies, actors in the electoral process, research organizations, and independent analysts. By aggregating data from multiple verified sources, we ensure that the information presented on our platform is both reliable and comprehensive.</p>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="city_health_wrap" style={{ paddingBottom: "150px",paddingTop:"40px" }}>
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="city_health_text" style={{textAlign:"justify"}}>
								<h2>Future Vision</h2>
								<p>As we continue to grow, our goal is to expand our data coverage and offer even more tools for analyzing and visualizing results. We envision a future where data-driven decision-making empowers every sector of the African society.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div>
								<img style={{ height: "200px", width: "500px", objectFit: "contain" }} src="https://th.bing.com/th/id/R.0b2ae634c9bfb7effbab6bc211f1f32c?rik=SCS90yLdxDBU6Q&pid=ImgRaw&r=0" alt="" />
							</div>
						</div>

					</div>

				</div>
			</div>
			

			</div>

			<Footer />
		</div>
	)
}
