import React from 'react'
import Header from './Header'
import Footer from './Footer'
import useFetch from '../ApiClient/GetApi'
import { IMAGE_URL } from '../Utils/urls';
import { formatDate } from '../Utils/DateFormat';

export default function Result() {
    const { apiData } = useFetch("getResults");
    console.log(apiData?.data?.results)
    return (
        <div>
            <Header />
            <div class="sab_banner overlay" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                <div class="container">
                    <div class="sab_banner_text">
                        <h2>Results</h2>
                    </div>
                </div>
            </div>
            <div class="city_blog2_wrap">
                <div class="container">
                    <div class="forum_list">
                        <table style={{ border: "3px solid white" }} class="table table-striped table-hover table-bordered shadow-sm">
                            <thead class="table-dark text-center">
                                <tr>
                                    <th scope="col">Polling Station</th>
                                    <th scope="col">Winner</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Party</th>
                                    <th scope="col">Vote Casts</th>
                                    <th scope="col">Rejected Votes</th>
                                    <th scope="col">Winner’s Votes</th>

                                    <th scope="col">File</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                {
                                    apiData?.data?.results?.map((result) => (
                                        <tr>
                                            <td>{result?.pollingstation?.name}</td>
                                            <td>{result?.candidate?.name}</td>
                                            <td>{result?.candidatetype?.name}</td>
                                            <td>{result?.party?.name}</td>
                                            <td>{result?.voteCasts}</td>
                                            <td>{result?.wrongVotes}</td>
                                            <td>{result?.winningCandidateVotes}</td>
                                            {
                                                result.file ?  <td><a className='btn btn-sm btn-success' href={IMAGE_URL + result?.file}>Download</a></td>:
                                                <td><a className='btn btn-sm btn-success'>No File</a></td>
                                            }
                                           
                                            <td>{formatDate(result?.createdAt)}</td>
                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>



                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
