import React from 'react';

export default function Footer() {
 

  return (
    <div>
      <footer>
        <div className="widget_wrap overlay" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
          <div className="" style={{ padding: "0px 60px" }}>
            <div className="row">
              <div className="col-md-2">
                <div className="widget_list">
                  <h4 className="widget_title">Explore</h4>
                  <div className='row' style={{ display: 'flex', justifyContent: "center" }}>
                    <div className="city_top_social" style={{ display: "contents" }}>
                      <ul>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-facebook"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-times"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-linkedin"></i></a>
                        </li>
                        <li style={{ border: '1px solid white' }}>
                          <a href="#"><i className="fa fa-instagram"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="widget_list">
                  <h4 className="widget_title">Address</h4>
                  <div className="widget_service">
                    <ul style={{textAlign:"center"}}>
                     
                      <li><p style={{ color: "white" }}>No.6 Saalai Street, Tesano, Accra</p></li>
                    </ul>
                  </div>

                 
                </div>
              </div>

              <div className="col-md-3">
                <div className="widget_list">
                  <h4 className="widget_title">Info</h4>
                  <div className="widget_service">
                    <ul style={{textAlign:"center"}}>
                      <li><p style={{ color: "white" }}>info@resultsinafrica.com</p></li>
                      <li><p style={{ color: "white" }}>+233509004055</p></li>
                     
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="widget_list">
                  <h4 className="widget_title">News Letter</h4>
                  <div className="widget_service">
                    <input type="text" name="text" placeholder="Email address" required />
                    <div className="city_news_search" style={{ marginTop: "5px" }}>
                      <button style={{ textAlign: "center" }} className="theam_btn border-color color">Subscribe Now</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="widget_copyright" style={{ padding: "10px 0px" }}>
                <p style={{ color: "white" }}>ResultsAfrica is a Grip360 Ltd Service</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
